
import { Form, FormItemProps, Input, InputNumber, Modal, Radio, Typography, Upload, UploadFile, UploadProps, message } from 'antd'
import { DeliveryInfoItem, PurchaseOrderDetailModel, TransitionButtonModel } from 'models/PurchaseOrder'
import { SearchForm, SearchItem } from 'pages/Components/SearchBox'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaCamera } from 'react-icons/fa'
import { MdCloudUpload } from 'react-icons/md'
import CustomButton from 'base/ui/components/Button';
import Webcam from 'react-webcam'
import { EditableFormInstance, EditableProTable, ProColumns, nanoid } from '@ant-design/pro-components'
import moment from 'moment'
import { RcFile } from 'antd/es/upload'
import { cloneDeep, filter } from 'lodash'
import { purchaseOrderPresenter, uiStore } from 'stores/root_store'
import { formatDecimalNumber, formatNumber, sFormatNumber } from 'utils/formatHelper'
import { customTableClassName } from 'base/ui/components/CustomTable'

type PopupConfirmProps = {
    handleUpdate: () => Promise<boolean>
    modalOpen: boolean
    onModalOpen: (open: boolean) => void
    buttonInfo?: TransitionButtonModel | TransitionButtonModel[]
    taskId: string
    reload: () => Promise<void>
    dataDetails?: PurchaseOrderDetailModel
}

export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const PopupConfirm = (props: PopupConfirmProps) => {
    const { buttonInfo, handleUpdate, modalOpen, onModalOpen, taskId, reload, dataDetails } = props
    const [cameraOpen, setCameraOpen] = useState(false)
    // chụp ảnh
    const webcamRef = React.useRef<any>(null);
    // danh sách file
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    // form 
    const [formExecute] = Form.useForm()
    // Cảnh báo upload ảnh (tối đa 2 ảnh)
    const [showWarningUpload, setShowWarningUpload] = useState<FormItemProps['validateStatus']>()
    const [messageUpload, setMessageUpload] = useState<string | undefined>()
    const editableFormRef = useRef<EditableFormInstance>();
    // Loading khi bấm nút
    const [loadingExecute, setLoadingExecute] = useState(false)

    // hàm chụp ảnh
    const capture = React.useCallback(() => {
        const today = new Date();
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            const tempt = fileList ? [...fileList] : [];
            if (tempt.length >= 2) {
                setShowWarningUpload('warning')
                setMessageUpload('Chỉ được thêm tối đa 2 hình ảnh.')
            } else {
                tempt.push({
                    uid: nanoid(),
                    // url: imageSrc,
                    thumbUrl: imageSrc,
                    preview: imageSrc,
                    name: `image_${moment(today).format('YYYY-MM-DD_HH:mm:ss')}`,
                });
            }
            setFileList(tempt);
            // message.success('Chụp ảnh thành công!');
            setCameraOpen(false);
        } else {
            message.error('Chụp ảnh thất bại!');
        }
    }, [webcamRef, fileList]);
    // hàm thay đổi của upload
    const handleChangeUploadFile: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        if (fileList.length <= 2) {
            setShowWarningUpload(undefined)
            setMessageUpload(undefined)
        }
        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(newFileList);
    };

    // Thực thi transition button
    const executeTransitionButton = async (values: any) => {
        setLoadingExecute(true)
        if (buttonInfo) {
            // onModalOpen(false)
            const files = fileList.map(async (file) => {
                if (file.originFileObj) {
                    return await getBase64(file.originFileObj as RcFile)
                } else return file.preview
            })
            // biến mảng promise thành array
            var arrayOfValues = await Promise.all(files)
            // có 2 btn đặt biệt là :"Đấ nhận hàng" và "Từ chối nhận hàng" 
            // 2 btn này được thay thế bằng btn "Xác nhận nhận hàng" và 2 btn này được đặt bên trong modal
            let dataExecute = {}
            // nếu là chuyển trạng thái bình thường
            if (!Array.isArray(buttonInfo)) {
                Object.assign(dataExecute, {
                    taskId: taskId,
                    statusTransitionId: buttonInfo.statusTransitionId,
                    comment: values?.comment,
                    files: arrayOfValues,
                    // fileAttachments: arrayOfValues,
                    documentNumber: values?.documentNumber
                })
                handleExecte(dataExecute)
            } else {
                editTableForm.validateFields().then((val) => {
                    const dataEdit = editTableForm.getFieldsValue()
                    const deliveryRegistrationItems = Object.entries(dataEdit).map(([key, values]) => {
                        const cloneValue = values as DeliveryInfoItem
                        return {
                            purchaseOrderDetailId: key,
                            inputQuantity: cloneValue?.inputQuantity,
                            deliveryStatus: cloneValue.deliveryStatus
                        }
                    })
                    Object.assign(dataExecute, {
                        taskId: taskId,
                        statusTransitionId: values.statusTransitionId,
                        comment: values?.comment,
                        deliveryRegistrationItems
                    })
                    handleExecte(dataExecute)
                }).catch((error) => { })
            }
        }
    }

    // call api execute và xử lý reload
    const handleExecte = async (dataExecute: any) => {
        handleUpdate().then(async () => {

            const success = await purchaseOrderPresenter.executeTransitionButton(dataExecute)
            if (success) {
                await reload()
                handleCloseModal()
            } else {
                setLoadingExecute(false)
            }
        }).catch((error) => {
            console.log(error);

        })
    }
    // Table 
    // =================================================================================================
    // form của table
    const [editTableForm] = Form.useForm()
    const [dataTable, setDataTable] = useState<DeliveryInfoItem[]>([])
    const [disableBtn, setDisableBtn] = useState({
        nhanHang: true,
        tuChoi: true
    })
    const columns: ProColumns<DeliveryInfoItem>[] = [
        {
            title: 'STT',
            dataIndex: 'index',
            valueType: 'index',
            width: 50,
        },
        {
            title: "Số PO",
            key: "purchaseOrderCode",
            dataIndex: "purchaseOrderCode",
            width: 100,
            readonly: true,
            // fixed: 'left',
            align: "center",
        },
        // "Đang làm nha"
        // {
        //     title: "Số PO",
        //     dataIndex: "PurchaseOrderCode"
        // }
        {
            title: "Tên hàng hóa",
            key: "productName",
            dataIndex: "productName",
            width: 200,
            readonly: true,
        },
        {
            title: "SL giao hàng",
            key: "deliveredQuantity",
            dataIndex: "deliveredQuantity",
            width: 130,
            onCell: () => ({
                className: 'text-right'
            }),
            readonly: true,
            renderFormItem(_, config) {
                if (config.record) {
                    const { record } = config
                    return <div>{record.deliveredQuantity !== undefined && record.deliveredQuantity !== null ? formatNumber(Number(record.deliveredQuantity), 3) : '-'}</div>
                }
                return <div>-</div>
            },
        },
        {
            title: "Kho",
            key: "stockCode",
            dataIndex: "stockCode",
            width: 100,
            readonly: true,
        },
        {
            title: "SL đã nhận",
            key: "inputQuantity",
            dataIndex: "inputQuantity",
            width: 150,
            // valueType: 'digit',
            onCell: () => ({
                className: 'text-right'
            }),

            renderFormItem(_, config) {
                return <InputNumber<number>
                    changeOnBlur
                    step={0.001}
                    onChange={(value) => {
                        const { record } = config
                        if (record && record?.openQuantity) {
                            if (value) {
                                const openQuantity = record.openQuantity - value
                                editTableForm.setFieldValue([record?.purchaseOrderDetailId, 'openQuantityTemp'], openQuantity)
                            } else {
                                editTableForm.setFieldValue([record?.purchaseOrderDetailId, 'openQuantityTemp'], record.openQuantity)
                            }
                        }
                    }}
                    // AVNMR-49 
                    // nếu từ chối nhận hàng thì disable sl đã nhận
                    disabled={config.record?.deliveryStatus === "TCNH" || config.record?.deliveryStatus === "CNH" ? true : false}
                    formatter={(value, info) => {
                        if (info.userTyping) {
                            return sFormatNumber(Number(value)) as string;
                        } else {
                            return formatDecimalNumber(`${sFormatNumber(Number(value))}`)
                        }
                    }}
                    controls={false} size="small" style={{
                        textAlignLast: 'right', width: '100%'
                    }}
                />
            },
            formItemProps(_, config) {
                return ({
                    rules: [{
                        type: 'number',
                    }, {
                        validator(_, value) {
                            // AVNMR-65
                            // Bắt thêm điều kiện nếu tick nhận hàng thù cột SL đã nhận luôn luôn > 0
                            if (editTableForm.getFieldValue([config.entity?.purchaseOrderDetailId, 'deliveryStatus']) === 'NH' && !value && value <= 0) {
                                return Promise.reject("Số lượng phải lớn hơn 0.")
                            }
                            const deliveredQuantity = config.entity?.deliveredQuantity || 0
                            if (value > (deliveredQuantity + deliveredQuantity * 0.1)) {
                                return Promise.reject("Số lượng đã nhận không được lớn hơn số lượng giao hàng.")
                            }
                            return Promise.resolve()
                        },
                    }],

                })
            },
        },
        // Số lượng còn lại (Ẩn để tính toán)
        {
            title: "SL còn lại",
            key: "openQuantity",
            dataIndex: "openQuantity",
            width: 130,
            readonly: true,
            hideInTable: true
        },
        // hiện thị kết quả sau tính toán
        {
            title: "Chênh lệch so với phiếu giao hàng",
            key: "openQuantityTemp",
            dataIndex: "openQuantityTemp",
            width: 120,
            onCell: () => ({
                className: 'text-right'
            }),
            readonly: true,
            renderFormItem(_, config) {
                if (config.record) {
                    const { record } = config
                    return <div>{record.openQuantityTemp !== undefined && record.openQuantityTemp !== null ? formatNumber(Number(record.openQuantityTemp), 3) : '-'}</div>
                }
                return <div>-</div>
            },
        },
        {
            key: 'deliveryStatus',
            dataIndex: "deliveryStatus",
            className: '!p-0 !w-0',
            width: 0,
            renderFormItem() {
                return <div></div>
            },
        },
        {
            title: "Chưa nhận hàng",
            key: "CNH",
            dataIndex: "deliveryStatus",
            width: 80,
            align: "center",
            renderFormItem(_, config) {
                return <Radio
                    checked={editTableForm.getFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus']) === 'CNH' || config.record?.deliveryStatus === "CNH"}
                    onChange={(e) => {
                        editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus'], "CNH")
                        checkDisabled()
                        if (e.target.checked) {
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'inputQuantity'], undefined)
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'openQuantityTemp'], config.record?.openQuantity)
                            editTableForm.validateFields()
                        }
                    }}
                />
            },
        },
        {
            title: "Nhận hàng",
            key: "NH",
            dataIndex: "deliveryStatus",
            width: 80,
            align: "center",
            renderFormItem(_, config) {
                return <Radio
                    checked={editTableForm.getFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus']) === 'NH' || config.record?.deliveryStatus === "NH"}
                    onChange={(e) => {
                        editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus'], "NH")
                        if (e.target.checked) {
                            setDisableBtn({ nhanHang: false, tuChoi: true })
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'inputQuantity'], config.record?.openQuantity)
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'openQuantityTemp'], 0)
                            editTableForm.validateFields()
                        }
                    }}
                />
            }
        },
        {
            title: "TC nhận hàng",
            key: "TCNH",
            dataIndex: "deliveryStatus",
            width: 80,
            align: "center",
            renderFormItem(schema, config) {
                return <Radio
                    checked={editTableForm.getFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus']) === 'TCNH' || config.record?.deliveryStatus === "TCNH"}
                    onChange={(e) => {
                        editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'deliveryStatus'], "TCNH")
                        checkDisabled()
                        if (e.target.checked) {
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'inputQuantity'], undefined)
                            editTableForm.setFieldValue([config.record?.purchaseOrderDetailId, 'openQuantityTemp'], config.record?.openQuantity)
                            editTableForm.validateFields()
                        }
                    }}
                />
            },
        }
    ]

    const renderUpload = (required?: boolean) => {
        return <>
            <SearchItem
                rules={[{
                    validator: () => {
                        if (required) {
                            if (!fileList.length) {
                                return Promise.reject('Vui lòng tải ảnh lên.')
                            }
                        }
                        return Promise.resolve()
                    }
                }]}
                name={'checkUpload'}
                required={required}
                validateStatus={showWarningUpload}
                help={messageUpload}
                label={<span>Đính kèm file<Typography.Text className='font-light' type='secondary'> (Tối đã 2 ảnh)</Typography.Text></span>}>
                <Upload
                    // accept='image/jpeg,image/png,application/pdf'
                    accept='image/jpeg,image/png'
                    onChange={handleChangeUploadFile}
                    fileList={fileList}
                    listType='picture'
                    beforeUpload={(file, list) => {
                        if (list.length > 2 || fileList.length === 2) {
                            setShowWarningUpload('warning')
                            setMessageUpload('Chỉ được thêm tối đa 2 hình ảnh.')
                        }
                        return false
                    }}
                    customRequest={() => { }}
                    onPreview={() => false}
                    multiple
                    maxCount={2}
                    showUploadList={{
                        showDownloadIcon: true,
                        showPreviewIcon: false,
                        showRemoveIcon: true
                    }}
                    className="[&_.ant-upload-list-item]:w-[220px] [&_.ant-upload-list-item]:float-left [&_.ant-upload-list-item]:me-3"
                >
                    <div className='flex gap-default'>
                        <CustomButton icon={<MdCloudUpload />} type='default' htmlType='button'>
                            Tải ảnh lên
                        </CustomButton>
                        <CustomButton
                            icon={<FaCamera />}
                            onClick={(e) => {
                                e.stopPropagation()
                                setCameraOpen(true)
                            }}
                            type='default'
                            htmlType='button'
                        >
                            Chụp ảnh
                        </CustomButton>
                    </div>
                </Upload>
            </SearchItem>
        </>
    }
    // kiểm tra disable của 2 nút từ chối và xác nhận
    const checkDisabled = useCallback(() => {
        const dataFormEditTable = editTableForm.getFieldsValue()
        const arr: DeliveryInfoItem[] = Object.values(dataFormEditTable)
        const check = arr.some((val) => val.deliveryStatus === 'NH')
        // Task AVNMR-49     BA: Phương Thảo
        //  Nếu 3 line đều chưa nhận hàng -> 2 nút k cho chọn. Nếu 1 trong 3 line có nhận hàng hoặc từ chối mới hiển thị
        const checkLength = filter(arr, ['deliveryStatus', "CNH"])

        if (!check && checkLength.length !== arr.length) {
            setDisableBtn({ nhanHang: true, tuChoi: false })
        } else if (check && checkLength.length !== arr.length) {
            setDisableBtn({ nhanHang: false, tuChoi: true })
        } else {
            setDisableBtn({ nhanHang: true, tuChoi: true })
        }
    }, [disableBtn, editTableForm])

    const handleCloseModal = () => {
        formExecute.resetFields()
        editTableForm.resetFields()
        setFileList([])
        onModalOpen(false)
        setShowWarningUpload(undefined)
        setMessageUpload(undefined)
        setLoadingExecute(false)
    }

    // render các item của form 
    const renderFormItem = () => {
        if (buttonInfo && !Array.isArray(buttonInfo)) {
            // nhấn nút vào cổng (CHECKIN) || Ra cổng (CHECKOUT)
            if (((buttonInfo.fromStatusCode === 'VT' || buttonInfo.fromStatusCode === 'CGH') &&
                (buttonInfo.toStatusCode === "CHECKIN" || buttonInfo.toStatusCode === "CHECKIN_KHO")) ||
                (buttonInfo.toStatusCode === "CHECKOUT")) {
                return (
                    <>
                        {/* Ghi chú */}
                        <SearchItem name={'comment'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label='Ghi chú' >
                            <Input.TextArea placeholder='Nhập ghi chú...' rows={2} />
                        </SearchItem>
                        {/* {renderUpload(buttonInfo.toStatusCode === "CHECKOUT")} */}
                        {renderUpload()}
                        {/* E-goods out (Số chứng từ ra cổng) */}
                        {(buttonInfo.toStatusCode === "CHECKOUT") && (
                            <SearchItem name={'documentNumber'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label='E-goods out (Số chứng từ ra cổng)' >
                                <Input placeholder='Nhập số chứng từ' />
                            </SearchItem>
                        )}
                        <div className='mt-4 flex justify-end gap-default'>
                            <CustomButton type='default' htmlType='button' loading={loadingExecute} onClick={() => handleCloseModal()}>Hủy</CustomButton>
                            <CustomButton type='primary' htmlType='submit' loading={loadingExecute}>Lưu</CustomButton>
                        </div>
                    </>)
            }
            // nhấn nút từ chối vào cổng
            else if ((buttonInfo.fromStatusCode === 'VT' && buttonInfo.toStatusCode === "TCVAOCONG") || (buttonInfo.fromStatusCode === 'CGH' && buttonInfo.toStatusCode === "TCVAOCONG")) {
                return (
                    <>
                        <SearchItem rules={[{ required: true, message: 'Chưa nhập lý do từ chối.' }]} name={'comment'} label='Lý do' >
                            <Input.TextArea placeholder='Nhập lý do...' rows={2} />
                        </SearchItem>
                        {renderUpload()}
                        <div className='mt-4 flex justify-end gap-default'>
                            <CustomButton type='default' htmlType='button' loading={loadingExecute} onClick={() => handleCloseModal()}>Hủy</CustomButton>
                            <CustomButton type='primary' htmlType='submit' loading={loadingExecute}>Lưu</CustomButton>
                        </div>
                    </>)
            }
            // Nút xác nhận đã cân
            else if (buttonInfo.fromStatusCode === 'CHECKIN' && (buttonInfo.toStatusCode === "CANVAO_KHO" || buttonInfo.toStatusCode === "CANVAO_CAN")) {
                return (
                    <>
                        <div className='my-2'><h2 className='text-center text-sm font-medium'>Xác nhận đã cân đầu vào xe giao hàng | {dataDetails?.deliveryRegistrationCode}</h2></div>
                        <div className='mt-2 flex justify-end gap-default'>
                            <CustomButton color='#52C41A' className='w-full' type='primary' loading={loadingExecute} htmlType='submit'>Xác nhận</CustomButton>
                        </div>
                    </>)
            }
            // Nút xác nhận cân ra
            else if ((buttonInfo.fromStatusCode === 'CANVAO_CAN' && buttonInfo.toStatusCode === "CANRA1") ||
                (buttonInfo.fromStatusCode === 'NHANHANG2' && buttonInfo.toStatusCode === "CANRA2") ||
                (buttonInfo.fromStatusCode === 'TUCHOINH2' && buttonInfo.toStatusCode === "CANRA2")) {
                return (
                    <>
                        <div className='my-2'><h2 className='text-center text-sm font-medium'>Xác nhận đã cân đầu ra xe giao hàng | {dataDetails?.deliveryRegistrationCode}</h2></div>
                        <div className='mt-2 flex justify-end gap-default'>
                            <CustomButton color='#52C41A' className='w-full' type='primary' loading={loadingExecute} htmlType='submit'>Xác nhận</CustomButton>
                        </div>
                    </>)
            }
            else {
                return <></>
            }
        }
        // Trường hợp bấm nút "Xác nhận nhận hàng"
        else if (buttonInfo && Array.isArray(buttonInfo)) {
            return (<>
                <SearchItem name={'comment'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label='Ghi chú' >
                    <Input.TextArea placeholder='Nhập ghi chú...' rows={2} />
                </SearchItem>
                <div className='mt-2 flex justify-end gap-default'>
                    {buttonInfo.filter(btn => !btn.isBackBtn).map((btn) => (
                        <CustomButton
                            key={btn.statusTransitionId}
                            type='primary'
                            loading={loadingExecute}
                            disabled={(() => {
                                if (loadingExecute) return loadingExecute
                                // Đã nhận hàng
                                if ((btn.fromStatusCode === 'CANVAO_KHO' && btn.toStatusCode === "NHANHANG2") ||
                                    (btn.fromStatusCode === 'CANRA1' && btn.toStatusCode === "NHANHANG1") ||
                                    (btn.fromStatusCode === 'CHECKIN_KHO' && btn.toStatusCode === "NHANHANG1")) {
                                    return disableBtn.nhanHang
                                }
                                // Từ chối nhận hàng 
                                else if ((btn.fromStatusCode === 'CANVAO_KHO' && btn.toStatusCode === "TUCHOINH2") ||
                                    (btn.fromStatusCode === 'CANRA1' && btn.toStatusCode === "TUCHOINH1") ||
                                    (btn.fromStatusCode === 'CHECKIN_KHO' && btn.toStatusCode === "TUCHOINH1")) {
                                    return disableBtn.tuChoi
                                }
                            })()}
                            onClick={() => formExecute.setFieldValue('statusTransitionId', btn.statusTransitionId)}
                            htmlType='submit'
                        >
                            {btn.statusTransitionName}
                        </CustomButton>
                    ))}
                </div>
            </>)
        }
        else {
            return <></>
        }
    }

    useEffect(() => {
        if (dataDetails && dataDetails.deliveryInfoItems && modalOpen) {
            const clone = cloneDeep(dataDetails.deliveryInfoItems)
            clone.forEach((item) => Object.assign(item, { openQuantityTemp: item.openQuantity, deliveryStatus: 'CNH' }))
            setDataTable(clone)
        }
        if (!modalOpen) {
            setDisableBtn({ nhanHang: true, tuChoi: true })
        }
    }, [dataDetails, modalOpen,]);

    return (
        <>
            <Modal
                onCancel={() => {
                    setFileList([])
                    handleCloseModal()
                }}
                className='[&_.ant-modal-footer]:!mt-0'
                width={buttonInfo && Array.isArray(buttonInfo) ? 1150 : undefined}
                open={modalOpen}
                footer={false}
                title={<h2 className='uppercase text-center'>{buttonInfo && !Array.isArray(buttonInfo) ? buttonInfo?.statusTransitionName : 'Xác nhận nhận hàng'}</h2>}
            >
                {buttonInfo && Array.isArray(buttonInfo) &&
                    <EditableProTable< DeliveryInfoItem>
                        editableFormRef={editableFormRef}
                        columns={columns}
                        dataSource={dataTable}
                        value={dataTable}
                        pagination={false}
                        className={
                            `${uiStore.theme.name}` + customTableClassName
                        }
                        editable={{
                            form: editTableForm,
                            editableKeys: dataTable.map(detail => detail.purchaseOrderDetailId)
                        }}
                        rowKey={'purchaseOrderDetailId'}
                        recordCreatorProps={false}
                        bordered
                    />}
                <SearchForm
                    form={formExecute}
                    onFinish={(values) => {
                        executeTransitionButton(values).catch((error) => {
                            console.log(error)
                        })
                    }}
                    column='col-1'>
                    {/* Transition id */}
                    <SearchItem hidden name='statusTransitionId'>
                        <Input />
                    </SearchItem>
                    {renderFormItem()}
                </SearchForm>

            </Modal>
            <Modal
                width={600}
                open={cameraOpen}
                destroyOnClose
                title={'Chụp ảnh'}
                footer={
                    <div className="flex justify-end">
                        <CustomButton
                            type="default"
                            onClick={() => {
                                capture();
                            }}
                        >
                            Chụp
                        </CustomButton>
                        <CustomButton className="text-white hover:!text-white" onClick={() => setCameraOpen(false)}>
                            Đóng
                        </CustomButton>
                    </div>
                }
                onCancel={() => setCameraOpen(false)}
            >
                {cameraOpen && (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        minScreenshotHeight={1080}
                        minScreenshotWidth={1080}
                    />
                )}
            </Modal>
        </>
    )
}

export default PopupConfirm