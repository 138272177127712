import * as Icon from 'base/ui/components/Icons';

import { Form, Input, Radio, message } from 'antd';
import { SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore } from 'stores/root_store';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomInput from 'base/ui/components/CustomInput';
import { SYSTEM_TITLE } from 'utils/constants';
import { getPlantCode } from 'utils/localStorageHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
// import { saveFirebase } from '..';

const createChangePassword = () => {
  return observer(() => {
    const { t } = useTranslation();

    const pagetitle = t('permission.account.title-change-password');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    // Get data params from url
    const { id: storeIdParams } = useParams();
    // Check button save and save continue
    let isSaveAndContinue = false;
    const [form] = Form.useForm();
    const [disalePass, setDisalePass] = useState(true);
    // Loading
    const [loading, setLoading] = useState(false);

    // =============================================================================================================
    //===================================================FUNCTION==================================================//
    // Call api get detail in store
    const getDetailAccountById = useCallback(async (accountId: string) => {
      await accountPresenter.getAccountById(accountStore, accountId);
      if (accountStore.accountDetail.isSuccess) {
        form.setFieldsValue(accountStore.accountDetail.data);
        setLoading(false);
      } else {
        navigate('/Permission/Account');
      }
    }, [form, navigate]);

    // Handle create company ================================
    const onFinish = async (values: any) => {
      setLoading(true);
      await accountPresenter.changePassword(accountStore, {
        ...values,
        accountId: storeIdParams,
        plantCode: getPlantCode(),
        isChangeDefaultPass: form.getFieldValue("newPassword") === "Ajinomoto@1234" ? true : false
      });
      if (accountStore.crudRes.isSuccess) {
        message.success(accountStore.crudRes.message);
        // Redirect to search page routing (Button Save)
        if (accountStore.accountDetail?.data?.userName)
          // saveFirebase(accountStore.accountDetail?.data?.userName, "");

          if (!isSaveAndContinue) {
            setTimeout(() => {
              navigate('/Permission/Account');
            }, 2000);
          }
      } else {
        message.error(accountStore.crudRes.message);
      }
      setLoading(false);
    };

    // const handlePasswordDefault = () => {

    //   if (form.getFieldValue('defaultPass') === true) {
    //     form.setFieldValue('newPassword', "Ajinomoto@1234");
    //     form.setFieldValue('confirm', "Ajinomoto@1234");
    //     setDisalePass(true);
    //   } else {
    //     form.setFieldValue('newPassword', "");
    //     form.setFieldValue('confirm', "");
    //     setDisalePass(false);
    //   }
    // }

    const handlePasswordDefault = (value: boolean) => {
      if (value) {
        form.setFieldsValue({
          newPassword: "Ajinomoto@1234",
          confirm: "Ajinomoto@1234",
        });
        // Ở trang dùng tài khoản mặc định thì set 2 state này là false, ở dưới có chỗ feedback={newPasswordDirty}, nghĩa là ở tab dùng default password thì sẽ không hiện cái feedback do feedback là false
        setNewPasswordDirty(false)
        setConfirmDirty(false)



        setDisalePass(true);
      } else {
        form.setFieldsValue({
          newPassword: "",
          confirm: "",
        });
        setDisalePass(false);
      }
    };



    useEffect(() => {
      if (storeIdParams) {
        (async () => {
          getDetailAccountById(storeIdParams);
        })();
      } else {
        navigate('/Permission/Account');
      }
      // handlePasswordDefault();
    }, [storeIdParams, getDetailAccountById, navigate]);

    // useEffect(() => {
    //   handlePasswordDefault();
    // }, [form.getFieldValue('defaultPass')])
    useEffect(() => {
      const defaultPass = form.getFieldValue('defaultPass');
      handlePasswordDefault(defaultPass);
    }, [form.getFieldValue('defaultPass')]);

    // Hàm validate để kiểm tra confirmPassword có giống với newPassword hay không
    const validateConfirmPassword = (_: any, value: any) => {
      if (value && value !== form.getFieldValue('newPassword')) {
        return Promise.reject(new Error('Mật khẩu xác nhận không khớp!'));
      }
      return Promise.resolve();
    };

    const [newPasswordDirty, setNewPasswordDirty] = useState(false);
    const [confirmDirty, setConfirmDirty] = useState(false);

    // Khi bắt đầu nhập vào trường confirmPassword
    const handleConfirmChange = (e: any) => {
      const { value } = e.target;
      if (e.target.name === "confirm")
        setConfirmDirty(!!value); // Đánh dấu confirmDirty khi có nhập liệu
      else {
        setNewPasswordDirty(!!value);
      }
    };


    return (
      <>
        <div className="flex flex-col h-full">
          <CustomBreadcrumb
            pagetitle={[
              {
                title: t('permission.account.title'),
                link: '/Permission/Account',
              },
              { title: pagetitle, link: `/Permission/Account/ChangePassword/${storeIdParams}` },
            ]}
            extra={
              <>
                <div className="custom__mobile--save flex space-x-2">
                  <CustomButton
                    loading={loading}
                    icon={<Icon.Download className="!fill-[#262626] " />}
                    htmlType="submit"
                    onClick={() => {
                      isSaveAndContinue = false;
                      form.submit();
                    }}
                  >
                    {t('btn.save')}
                  </CustomButton>
                  <CustomButton
                    loading={loading}
                    type="primary"
                    icon={<Icon.Download />}
                    onClick={() => {
                      isSaveAndContinue = true;
                      form.submit();
                    }}
                  >
                    Lưu và tiếp tục cập nhật
                  </CustomButton>
                </div>
              </>
            }
          />

          {/* Form nhập tìm kiếm */}
          <BoxContainer fullHeight>
            <SearchForm onFinish={onFinish} form={form} autoComplete='off'>
              <SearchItem className="m-0 mb-2" name={'defaultPass'} initialValue={true}>
                <Radio.Group onChange={(e) => handlePasswordDefault(e.target.value)}>
                  <Radio value={true}> {'Mật khẩu mặc định'}</Radio>
                  <Radio value={false}>{'Nhập mật khẩu'}</Radio>
                </Radio.Group>
              </SearchItem>
              <SearchItem />
              {/* tên tài khoản */}
              <SearchItem
                rules={[{ required: true, message: 'Hãy nhập thông tin cho trường tên tài khoản' }]}
                name={'userName'}
                label={t('permission.account.username')}
              >
                <CustomInput disabled />
              </SearchItem>
              {/* Mật khẩu mới */}
              <SearchItem
                hasFeedback={newPasswordDirty}
                validateTrigger="onChange"
                normalize={(value) => value.trim()}
                rules={[{ required: true, message: 'Hãy nhập thông tin cho trường mật khẩu mới' },
                {
                  min: 8,
                  message: 'Mật khẩu có độ dài tối thiểu 8 ký tự.',
                },
                {
                  pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message: 'Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số, 1 ký tự đặc biệt.',
                },
                ]}
                name={'newPassword'}
                label={t('permission.account.new-password')}

              >
                <Input.Password className="rounded-none" disabled={disalePass} name="newPassword" onChange={handleConfirmChange} />
              </SearchItem>
              {/* Xác nhận mật khẩu mới */}
              <SearchItem
                hasFeedback={confirmDirty}
                validateTrigger="onChange"
                normalize={(value) => value.trim()}

                dependencies={['newPassword']}
                rules={[
                  {
                    required: confirmDirty, // Chỉ yêu cầu khi trường đã "dirty"
                    message: t('message.please-check-password'),
                  },
                  {
                    validator: validateConfirmPassword,
                  },
                ]}
                name={'confirm'}
                label={t('permission.account.confirm-new-password')}
              >
                <Input.Password className="rounded-none" name="confirm" disabled={disalePass} onChange={handleConfirmChange} />
              </SearchItem>
            </SearchForm>
          </BoxContainer>
        </div>
      </>
    );
  });
};

export default createChangePassword;
