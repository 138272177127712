
// // Key ghi nhớ mật khẩu
// const ENCRYPT_KEY = '3fiYLDFb8yNHPqEyUGMe62fJpnyaNXuM';
// // ====MÃ HÓA===============================================================================
// export const encrypt = (text: string): string => {
//     return AES.encrypt(text, ENCRYPT_KEY).toString();
// };

// export const decrypt = (text: string): string => {
//     return AES.decrypt(text, ENCRYPT_KEY).toString(enc.Utf8);
// };

const ENCRYPT_KEY = "3fiYLDFb8yNHPqEyUGMe62fJpnyaNXuM"; // Thay thế bằng khóa thực tế của bạn
const ivLength = 12; // Độ dài của IV cho AES-GCM (khuyến nghị)

function getKey() {
    return crypto.subtle.importKey(
        "raw",
        new TextEncoder().encode(ENCRYPT_KEY),
        { name: "AES-GCM" },
        false,
        ["encrypt", "decrypt"]
    );
}

function uint8ArrayToString(uint8Array: any) {
    let result = '';
    for (let i = 0; i < uint8Array.length; i++) {
        result += String.fromCharCode(uint8Array[i]);
    }
    return result;
}
// Mã hóa chuỗi
export const encrypt = async (text: any) => {
    const key = await getKey();
    const iv = crypto.getRandomValues(new Uint8Array(ivLength)); // Vector khởi tạo (IV)
    const encodedText = new TextEncoder().encode(text);

    const encrypted = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv },
        key,
        encodedText
    );

    // Kết hợp IV với ciphertext để giải mã sau này
    const encryptedArray = new Uint8Array(encrypted);
    const combinedArray = new Uint8Array(iv.length + encryptedArray.length);
    combinedArray.set(iv);
    combinedArray.set(encryptedArray, iv.length);

    // Trả về dữ liệu đã mã hóa dưới dạng chuỗi base64
    return btoa(uint8ArrayToString(combinedArray));
};

// Giải mã chuỗi
export const decrypt = async (encryptedText: any) => {
    const combinedArray = new Uint8Array(atob(encryptedText).split("").map(char => char.charCodeAt(0)));
    const iv = combinedArray.slice(0, ivLength);
    const ciphertext = combinedArray.slice(ivLength);

    const key = await getKey();

    const decrypted = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv },
        key,
        ciphertext
    );

    // Trả về chuỗi đã giải mã
    return new TextDecoder().decode(decrypted);
};
