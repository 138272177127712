import { observer } from 'mobx-react'
import CustomButton from 'base/ui/components/Button';
import { ConfigProvider, Form, Input, Spin } from 'antd';
import { MdQrCodeScanner } from "react-icons/md";
import { userPresenter, userStore } from 'stores/root_store';
import { SearchItem } from 'pages/Components/SearchBox';
import { useState } from 'react';
// import { getDatabase, ref, update } from 'firebase/database';
// import firebaseApp from '../../../firebase/config';
// import { FIREBASE_USERS } from 'utils/constants';

const createVerifyQR = () => {
    return observer(() => {
        if (!userStore.userRespone.data) {
            window.location.replace('/login')
            return (
                <div className='w-full h-full flex justify-center items-center'>
                    <Spin />
                </div>
            )
        }
        const [form] = Form.useForm()
        const qrUrl = userStore.userRespone.data?.qrCode
        const [loading, setLoading] = useState(false)

        // const saveFirebase = (username: string, refreshToken: string) => {
        //     const db = getDatabase(firebaseApp);
        //     const todoRef = ref(db, `/${FIREBASE_USERS || 'mes-dev-users'}/` + username);

        //     const todo = {
        //         refreshToken: refreshToken,
        //     };
        //     update(todoRef, todo).catch((e) => console.log(e));
        // };

        //   xác thực otp
        const verifyOtp = async (value: any) => {
            setLoading(true)
            const result = await userPresenter.confirmOTP({ userName: userStore.userRespone.data!.userName, otp: value.otp || '', saleOrg: userStore.userRespone.data!.saleOrgCode })
            setLoading(false)
            // nếu xác thực thành công thì lưu refeshToken lên firebase
            if (result) {
                const userData = userStore.userRespone.data!;
                userPresenter.setCurrentUser(userStore, userData.refreshToken || null);
                // saveFirebase(userData.userName || '', userData.refreshToken || '');
                window.location.reload()
            } else {
                form.resetFields()
            }
        }
        return (
            <div className='w-full h-full flex justify-center items-center'>
                <div className="w-[400px] min-h-[500px] bg-white p-12 py-9 rounded shadow-sm">
                    <div className="w-full text-center flex flex-col items-center mb-12 gap-2">
                        <MdQrCodeScanner className='text-2xl text-[#52C41A]' />
                        <h2 className="uppercase font-semibold">Quét mã QR để xác thực 2 bước</h2>
                        <hr className='w-1/2' />
                    </div>
                    <div className="">
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: '#52C41A'
                                },
                            }}
                        >
                            <div className="w-full flex justify-center mb-4">
                                <div className="w-[200px] h-[200px] border">
                                    <img className='w-full' src={qrUrl} alt="QRcode" />
                                </div>
                            </div>
                            <Form
                                form={form}
                                layout='vertical'
                                className='text-default font-medium mb-[2px]'
                                onFinish={(values) => {
                                    verifyOtp(values)
                                }}
                            >
                                <div className="mb-5">
                                    {/* <label htmlFor="otp" className='text-default font-medium mb-[2px]'>OTP</label>
                                <Input value={otp} id='otp' placeholder='Mã OTP của bạn' onChange={(e) => {
                                    setOtp(e.target.value)
                                }} /> */}
                                    <SearchItem label='OTP' name={'otp'}>
                                        <Input placeholder='Mã OTP của bạn' />
                                    </SearchItem>
                                </div>
                                <CustomButton loading={loading} htmlType='submit' className='w-full' type='primary' >Xác thực</CustomButton>
                            </Form>
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        )
    })
}

export default createVerifyQR