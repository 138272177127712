import './styles.css';

import * as Icon from 'base/ui/components/Icons';

import { ActionType, nanoid, ProColumns } from '@ant-design/pro-components';
import { Checkbox, Divider, Form, Input, InputRef, Modal, Select, Space, Tooltip, message } from 'antd';
import CustomProTable, { tablePagination } from 'base/ui/components/CustomTable';
import { DataSearchAccount, statusList } from '../model';
import { Link, useNavigate } from 'react-router-dom';
// FIREBASE_USERS,
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTION, SYSTEM_TITLE } from 'utils/constants';
import { SearchBox, SearchForm, SearchItem } from 'pages/Components/SearchBox';
import { accountPresenter, accountStore, notificationPresenter, notificationStore, userStore, vendorPresenter } from 'stores/root_store';
import { useCallback, useEffect, useRef, useState } from 'react';
// import app from '../../../../firebase/config';
import BoxContainer from 'base/ui/components/BoxContainer';
import CustomBreadcrumb from 'base/ui/components/CustomBreadcrumb';
import CustomButton from 'base/ui/components/Button';
import CustomButtonIcon from 'base/ui/components/Button/buttonIcon';
import CustomInput from 'base/ui/components/CustomInput';
import CustomSelect from 'base/ui/components/CustomSelect';
import { DataAccount } from 'services/RawData/account_services';
import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import { ExportButton } from 'pages/Components/FunctionButton';
import { FaClock, FaFilter, FaKey, FaLock, FaUnlock } from 'react-icons/fa';
import { GrPowerReset } from "react-icons/gr";
import { HiSearch } from 'react-icons/hi';
import { SearchModel } from 'pages/model';
import { cloneDeep, random, uniq } from 'lodash';
import { observer } from 'mobx-react';
import { usePermission } from 'hooks/usePermission';
import { useTranslation } from 'react-i18next';
import { IoMdUnlock } from 'react-icons/io';
import { getPlantCode } from 'utils/localStorageHelper';
import { RiChatHistoryLine } from "react-icons/ri";
// import { getDatabase, ref, update } from 'firebase/database';
import { FilterDropdownProps } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { CheckboxProps } from 'antd/lib';
import Button from 'base/ui/components/Button';

const { confirm } = Modal;

// /**
// * Hàm xử lý đẩy thông tin user lên firebase để lưu trữ
// * @param username
// * @param refreshToken
// */
// export const saveFirebase = (username: string, refreshToken: string) => {
//   const db = getDatabase(app);
//   const todoRef = ref(db, `/${FIREBASE_USERS || 'mes-dev-users'}/` + username);

//   const todo = {
//     refreshToken: refreshToken,
//   };
//   update(todoRef, todo).catch((e) => console.log(e));
// };

const accountPage = () => {
  return observer(() => {
    usePermission('INDEX'); // Kiểm tra xem được quyền truy cập vào trang

    const { t } = useTranslation();

    const pagetitle = t('permission.account.title');
    document.title = pagetitle + ' - ' + SYSTEM_TITLE;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    // state ===============================================================================
    const [openDrawer, setOpenDrawer] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////////////////
    // FILTER TỪNG CỘT
    const searchInput = useRef<InputRef>(null);
    const [searchText, setSearchText] = useState<Record<string, string>>({});
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableKey, setTableKey] = useState(0)
    const handleSearchFilter = (
      selectedKeys: string[],
      confirm: FilterDropdownProps['confirm'],
      dataIndex: keyof DataAccount,
    ) => {
      confirm();
      // setSearchText(selectedKeys);
      setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void, confirm: FilterDropdownProps['confirm'], dataIndex: keyof DataAccount) => {
      clearFilters();
      confirm()
      setSearchText(prev => ({ ...prev, [dataIndex]: '' }))
      // setSearchText([]);
    };


    // Loading all page
    const [loading, setLoading] = useState(false);

    // Data search
    const [dataSearch, setDataSearch] = useState<SearchModel>({
      paging: {
        pageIndex: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: undefined as string | undefined,
        orderByDesc: undefined as string | undefined,
      },
    });
    const [listDataAccount, setListDataAccount] = useState<DataAccount[]>([]);


    const getColumnSearchProps = (dataIndex: keyof DataAccount, type?: 'date' | 'account-ncc' | 'status'): ProColumns<DataAccount> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Tìm kiếm`}
            value={selectedKeys.length < 2 ? selectedKeys[0] : undefined}
            onChange={(e) => setSearchText(prev => ({ ...prev, [dataIndex]: e.target.value }))}
            // onPressEnter={() => handleSearchFilter(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <hr className="mb-2 mt-1" />

          {(() => {
            let arrString = [] as string[];
            switch (type) {

              case 'date':
                const arrDate = listDataAccount.map(data => data[dataIndex] ? dayjs(data[dataIndex] as string).format('DD/MM/YYYY') : '-') as string[];
                //const sorted = sortedUniqBy(arrDate, (date) => new Date(date).getTime());
                // arrString = sorted.map(data => data !== '-' ? dayjs(data).format('DD/MM/YYYY') : data)
                //Lọc ra những phần tử duy nhất => sau đó chuyển về kiểu mảng
                const newSet = new Set(arrDate);
                const sorted = Array.from(newSet);

                arrString = sorted
                break;
              case 'account-ncc':
                const arrAccountNcc = listDataAccount.map(data => data[dataIndex] ? "Có" : "Không") as string[];
                const newSetAccountNcc = new Set(arrAccountNcc);
                const sortedAccountNcc = Array.from(newSetAccountNcc);
                arrString = sortedAccountNcc
                break;
              case 'status':
                const arrStatus = listDataAccount.map(data => data[dataIndex] ? "Đang hoạt động" : "Ngưng hoạt động") as string[];
                const newSetStatus = new Set(arrStatus);
                const sortedStatus = Array.from(newSetStatus);
                arrString = sortedStatus
                break;
              default:
                arrString = listDataAccount.map(data => data[dataIndex] ? data[dataIndex] : '-') as any[];
                break;
            }
            // loại bỏ các mã trùng
            const uniqueArr = uniq(arrString);
            const searchArr = uniqueArr.filter(i => searchText ? (i + '').toLowerCase().includes(searchText[dataIndex]?.toLowerCase() || '') : true)
            // const searchArr = uniqueArr.filter(i => searchText[dataIndex]
            //   ? (i + '').toLowerCase().includes(searchText[dataIndex].toLowerCase())
            //   : true);
            const checkedAll = searchArr.length === selectedKeys.length
            const indeterminate = selectedKeys.length > 0 && selectedKeys.length < searchArr.length;
            const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
              setSelectedKeys(e.target.checked ? searchArr as string[] : []);
              // setSearchText(e.target.checked ? uniqueArr as string[] : [])
            };

            return (
              <Space direction='vertical' className="max-h-[250px] overflow-y-auto w-full" >
                <Checkbox checked={checkedAll}
                  onChange={onCheckAllChange} indeterminate={indeterminate}>Tất cả</Checkbox>
                <Checkbox.Group value={selectedKeys as string[]} onChange={(checkValues) => {

                  setSelectedKeys(checkValues as string[])
                  // setSearchText(checkValues as string[])
                }}>
                  <Space direction='vertical'>
                    {
                      searchArr.map((item, index) => (
                        <Checkbox key={nanoid()} value={item} >
                          {item}
                        </Checkbox>
                      ))
                    }
                  </Space>
                </Checkbox.Group>

              </Space>
            )
          })()}

          <hr className="mb-2 mt-1" />
          <Space>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => handleSearchFilter(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <FaFilter style={{ color: filtered ? '#1677ff' : undefined }} />
      ),
      onFilter: (value, record) => {
        if (type === 'date') {
          return (record[dataIndex] ? dayjs(record[dataIndex] as string).format("DD/MM/YYYY") : '-') === (value as string)
        }
        if (type === 'account-ncc') {
          return (record[dataIndex] ? "Có" : "Không") === (value as string)
        }
        if (type === 'status') {
          return (record[dataIndex] ? "Đang hoạt động" : "Ngưng hoạt động") === (value as string)
        }
        return (record[dataIndex] || '-')
          .toString()
          .toLowerCase() === (value as string).toLowerCase()

      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      // render: (text, record) =>
      //     searchedColumn === dataIndex ? (
      //         <Highlighter
      //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      //             searchWords={searchText}
      //             autoEscape
      //             // textToHighlight={text ? text.toString() : ''}
      //             textToHighlight={`${record[dataIndex] ? record[dataIndex] : ''}`}
      //         />
      //     ) : (
      //         text
      //     ),
    });

    // Columns table title
    const defaultColumn: ProColumns<DataAccount>[] = [
      // stt
      {
        title: t('permission.account.stt'),
        dataIndex: 'stt',
        key: 'stt',
        width: 60,
        align: 'center',
        className: '!rounded-none !py-1',
        render: (text) => <div className="!text-center">{text}</div>,
        fixed: 'left',
      },

      {
        title: t('permission.account.username'),
        dataIndex: 'userName',
        key: 'userName',
        width: 150,
        className: '!rounded-none !py-1',
        fixed: 'left',
        ...getColumnSearchProps('userName')
      },
      // Tên nhân viên/Nhà cung cấp
      {
        title: 'Tên nhân viên/Nhà cung cấp',
        dataIndex: 'employeeName',
        key: 'employeeName',
        width: 250,
        ...getColumnSearchProps('employeeName')
      },
      // Email
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: 200,
        ...getColumnSearchProps('email')
      },
      // Số điện thoại
      {
        title: 'Số điện thoại',
        dataIndex: 'phone',
        key: 'phone',
        width: 130,
        ...getColumnSearchProps('phone')
      },
      // Nhóm người dùng
      {
        title: 'Nhóm người dùng',
        dataIndex: 'listRoleName',
        key: 'listRoleName',
        width: 250,
        ...getColumnSearchProps('listRoleName')
      },
      // Tài khoản NCC
      {
        title: 'Tài khoản NCC',
        dataIndex: 'isSupplier',
        key: 'isSupplier',
        align: 'center',
        width: 130,
        render: (_, record) => (
          <div className="w-full flex justify-center">
            {record.isSupplier && (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            )}
          </div>
        ),
        ...getColumnSearchProps('isSupplier', 'account-ncc')
      },
      // Người tạo
      {
        title: 'Người tạo',
        dataIndex: 'createByName',
        key: 'createByName',
        width: 240,
        ...getColumnSearchProps('createByName')
      },
      // Thời gian tạo
      {
        title: 'Thời gian tạo',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 150,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
        ...getColumnSearchProps('createTime', 'date')
      },
      // Lần đăng nhập cuối
      {
        title: 'Lần đăng nhập cuối',
        dataIndex: 'lastLogin',
        key: 'lastLogin',
        width: 150,
        align: 'center',
        valueType: 'dateTime',
        fieldProps: {
          format: 'DD/MM/YYYY HH:mm:ss',
        },
        ...getColumnSearchProps('lastLogin', 'date')
      },
      // trạng thái
      {
        title: t('permission.account.status'),
        dataIndex: 'actived',
        width: 100,
        key: 'actived',
        align: 'center',
        render: (text) => (
          <div className="w-full flex justify-center">
            {text ? (
              <Icon.CheckStatus className="!fill-[#389e0d]" width="15" />
            ) : (
              <Icon.CloseStatus width="15" className="!fill-red-500" />
            )}
          </div>
        ),
        ...getColumnSearchProps('actived', 'status')
      },
      // Chức năng
      {
        title: t('permission.account.actions'),
        dataIndex: '',
        key: '',
        width: 170,
        className: '!rounded-none !py-1',
        fixed: 'right',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="w-full flex items-center justify-center gap-x-1">
              {userStore.userPermission?.includes('EDIT') ? (
                <Tooltip title={t('btn.edit')}>
                  <CustomButtonIcon color="#007BF1" onClick={() => navigate(`${record.accountId}`)}>
                    <Icon.PencilEdit className="!fill-[#007BF1]" width="14" height="14" />
                  </CustomButtonIcon>
                </Tooltip>
              ) : null}
              {/* {userStore.userPermission?.includes('DELETE') ? (
                  <Tooltip title={t('btn.delete')}>
                    <CustomButtonIcon
                      color="#FC5C5E"
                      onClick={() => {
                        showDeleteConfirm(record);
                      }}
                    >
                      <Icon.Trash className="!fill-[#FC5C5E]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Tooltip>
                ) : null} */}
              {userStore.userPermission?.includes('CHANGE_PASSWORD') ? (
                <Tooltip title={t('btn.change-password')}>
                  <Link to={`ChangePassword/${record.accountId}`}>
                    <CustomButtonIcon color="#e08e0b">
                      <FaKey className="!fill-[#e08e0b]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Link>
                </Tooltip>
              ) : null}
              {userStore.userPermission?.includes('RESET_PASSWORD') && (
                <Tooltip title={"Reset password"}>
                  <Link to={`ResetPassword/${record.accountId}`}>
                    <CustomButtonIcon color="#389e0d" >
                      <GrPowerReset className="text-[#389e0d]" width="14" height="14" />
                    </CustomButtonIcon>
                  </Link>
                </Tooltip>
              )}
              {userStore.userPermission?.includes('LOCK') && record.actived &&
                <Tooltip title={t('btn.lock-account')}>
                  <CustomButtonIcon
                    color="#ef4444"
                    onClick={() => {
                      showLockAccountConfirm(record);
                    }}
                  >
                    <FaLock className='text-[#ef4444] w-[14px] h-[14px]' />
                  </CustomButtonIcon>
                </Tooltip>
              }
              {userStore.userPermission?.includes('UNLOCK') && !record.actived &&
                <Tooltip title={t('btn.unblock-account')}>
                  <CustomButtonIcon
                    color="#389e0d"
                    onClick={() => {
                      showUnblockAccountConfirm(record);
                    }}
                  >
                    <FaUnlock className='text-[#389e0d] w-[14px] h-[14px]' />
                  </CustomButtonIcon>
                </Tooltip>}
              {userStore.userPermission?.includes('VIEW_HISTORY') &&
                <Tooltip title={"Xem lịch sử"}>
                  <CustomButtonIcon
                    color="#1e293b"
                    onClick={() => navigate(`History/${record.accountId}`)}
                  >
                    <RiChatHistoryLine className='text-[#1e293b] w-[14px] h-[14px]' />
                  </CustomButtonIcon>
                </Tooltip>}
            </div>
          );
        },
      },
    ];



    useEffect(() => {
      if (notificationStore.createDate) {
        form.setFieldsValue({
          purchaseOrderCodes: notificationStore.listPOAsynch,
          createTimeFrom: notificationStore.createDate && dayjs(notificationStore.createDate),
          createTimeTo: notificationStore.createDate && dayjs(notificationStore.createDate),
          common1: 'Custom'
        })
        const search = {
          ...dataSearch,
          purchaseOrderCodes: notificationStore.listPOAsynch,
          createTimeFrom: notificationStore.createDate && dayjs(notificationStore.createDate).format("YYYY-MM-DD"),
          createTimeTo: notificationStore.createDate && dayjs(notificationStore.createDate).format("YYYY-MM-DD")
        }
        setDataSearch(search)
        getDataSearchAccount(search)
      }
    }, [notificationStore.createDate, notificationStore.listPOAsynch]);

    useEffect(() => {
      getDataSearchAccount(dataSearch)

      return () => {
        notificationPresenter.setCreateDate(notificationStore, undefined)
        notificationPresenter.setListPOAsynch(notificationStore, [])
      }
    }, []);

    // Custom default columns table with resizeable
    const [columns, setColumn] = useState<ProColumns<DataAccount>[]>(defaultColumn);

    // set lại colum khi data table thay đổi 
    // cập nhật lại filter
    useEffect(() => {
      setColumn(prev => {
        const col = defaultColumn.map((column, index) => ({ ...column, width: prev[index].width }))
        return col
      })
    }, [listDataAccount, searchText]);

    // ref của table
    const tableRef = useRef<ActionType>();

    // function open hide drawer ===========================================================
    const showDrawer = () => {
      setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
      setOpenDrawer(false);
    };

    // Call api search Data
    const getDataSearchAccount = async (dataSearch: DataSearchAccount) => {
      setLoading(true)
      await accountPresenter.searchAccount(accountStore, { ...dataSearch, paging: { ...dataSearch.paging, pageSize: 1000000000 } });
      setLoading(false)
      if (accountStore.listAccountResponse.isSuccess) {
        setListDataAccount(accountStore.listAccountResponse.data);
        const cloneData = cloneDeep(accountStore.listAccountResponse.data)
        return cloneData
      }
      return []
    };


    const getDropDownRole = async () => {
      await accountPresenter.getDropdownRoles(accountStore)
    }

    // Handle click button search
    const handleSearch = async (values: any) => {
      // setLoading(true);
      const tempDataSearch = {
        paging: {
          ...dataSearch.paging,
          pageIndex: 1,
        },
        ...values,
      };
      // set pageIndex 1 when search form
      // setDataSearch(tempDataSearch);
      await getDataSearchAccount(tempDataSearch)
      // tableRef.current?.reload()
      // setLoading(false);
      onCloseDrawer();

      // setListDataAccount([])
      // setTableKey(prev => prev + 1)
      // setSearchText({})
    };

    // scroll to top
    useEffect(() => {
      getDropDownRole()
      window.scrollTo(0, 0);
    }, []);

    // initialValues of form search
    const initialValues = {};

    // Call api delete
    const handleClickDeleteAccount = async (accountId: string) => {
      setLoading(true);
      if (accountId) {
        await accountPresenter.deleteAccountById(accountStore, accountId);
        if (accountStore.crudRes.isSuccess) {
          message.success(accountStore.crudRes.message);
          // Call list branch after change
          // await getDataSearchAccount(dataSearch);
          tableRef.current?.reload()
        } else {
          message.error(accountStore.crudRes.message);
        }
      }
      setLoading(false);
    };

    // Call api lock/unblock account
    const handleLockOrUnblockAccount = async (accountId: string, type: "lock" | "unlock", username?: string) => {
      setLoading(true);
      if (type === "unlock")
        await accountPresenter.unblockAccountById(accountStore, { accountId, plantCode: getPlantCode(), isChangeDefaultPass: true });
      else
        await accountPresenter.deleteAccountById(accountStore, accountId);


      if (accountStore.crudRes.isSuccess) {
        message.success(accountStore.crudRes.message);
        // Call list branch after change
        // await getDataSearchAccount(dataSearch);
        // if (username)
        //   saveFirebase(username, "");

        form.submit();
        // tableRef.current?.reload()
      } else {
        message.error(accountStore.crudRes.message);
      }
      setLoading(false);
    };


    // Ui modal confirm delete store
    const showDeleteConfirm = (item: DataAccount) => {
      confirm({
        title: `${t('btn.delete')} ${item.userName}`,
        icon: <ExclamationCircleFilled />,
        content: `${t('message.question-delete')} ${item.userName}`,
        okText: t('btn.delete'),
        okType: 'danger',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--delete',
        onOk() {
          handleClickDeleteAccount(item.accountId);
        },
        onCancel() { },
      });
    };

    const showUnblockAccountConfirm = (item: DataAccount) => {
      confirm({
        title: (
          <span>
            {t('btn.unblock-account')}{' '}
            <span style={{ color: '#389e0d' }}>{item.fullName}</span>
          </span>
        ),
        icon: <FaUnlock size={20} className='text-[#389e0d] mr-2 mt-1' />,
        content: (
          <span>
            {t('message.question-unblock')}{' '}
            <span style={{ fontWeight: 'bold' }}>{item.fullName}</span>
          </span>
        ),
        okText: t('btn.unblock-account'),
        okType: 'primary',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--unblock',
        onOk() {
          handleLockOrUnblockAccount(item.accountId, "unlock");
        },
        onCancel() { },
      });
    };

    const showLockAccountConfirm = (item: DataAccount) => {
      confirm({
        title: (
          <span>
            {t('btn.lock-account')}{' '}
            <span style={{ color: '#ef4444' }}>{item.fullName}</span>
          </span>
        ),
        icon: <FaLock size={20} className='text-[#ef4444] mr-2 mt-1' />,
        content: (
          <span>
            {t('message.question-lock')}{' '}
            <span style={{ fontWeight: 'bold' }}>{item.fullName}</span>
          </span>
        ),
        okText: t('btn.lock-account'),
        okType: 'primary',
        cancelText: t('btn.cancel'),
        className: 'custom__modalconfirm--lock',
        onOk() {
          handleLockOrUnblockAccount(item.accountId, "lock", item.userName);
        },
        onCancel() { },
      });
    }



    return (
      <div className="w-full">
        {/* ================ Breadcrumb =============== */}
        <CustomBreadcrumb
          pagetitle={[{ title: pagetitle, link: '/Permission/Account' }]}
          extra={
            <>
              <CustomButton onClick={showDrawer} type="primary" color="#EFF4F9" className="!px-0 w-8">
                <Icon.Filter className="fill-primary" />
              </CustomButton>

              {userStore.userPermission?.includes('CREATE') ? (
                <CustomButton
                  onClick={() => {
                    navigate('create');
                  }}
                  type="primary"
                  icon={<Icon.Plus className="!fill-white" />}
                >
                  {t('btn.add')}
                </CustomButton>
              ) : null}
            </>
          }
        />
        {/* Table */}
        <BoxContainer>
          <CustomProTable<DataAccount>
            columns={columns}
            rowKey="stt"
            size="small"
            bordered
            key={tableKey}
            search={false}
            actionRef={tableRef}
            // request={async (params = {}, sort, filter) => {
            //   const data = await getDataSearchAccount({
            //     ...dataSearch,
            //     paging: {
            //       ...dataSearch.paging,
            //       orderBy:
            //         Object.values(sort)[0] === "ascend"
            //           ? `${Object.keys(sort)[0].split(",").pop()}`
            //           : undefined,
            //       orderByDesc:
            //         Object.values(sort)[0] === "descend"
            //           ? `${Object.keys(sort)[0].split(",").pop()}`
            //           : undefined,
            //     },
            //   });
            //   if (Object.values(sort).length) {

            //     setDataSearch({
            //       ...dataSearch,
            //       paging: {
            //         ...dataSearch.paging,
            //         orderBy:
            //           Object.values(sort)[0] === "ascend"
            //             ? `${Object.keys(sort)[0].split(",").pop()}`
            //             : undefined,
            //         orderByDesc:
            //           Object.values(sort)[0] === "descend"
            //             ? `${Object.keys(sort)[0].split(",").pop()}`
            //             : undefined,
            //       },
            //     });
            //   }
            //   return {
            //     data: data,
            //     success: accountStore.listAccountResponse.isSuccess,
            //   };

            // }}
            dataSource={listDataAccount}
            scroll={{ y: 500 }}
            pagination={{
              ...tablePagination(dataSearch, setDataSearch),
              // total: accountStore.listAccountResponse?.paging?.recordsTotal,
            }}
            loading={loading}
            options={false}
          />
        </BoxContainer>
        {/* Form search */}
        <SearchBox
          className="lg:!w-[35%]"
          footer={[
            <div key="footer" className="flex justify-end">
              <CustomButton
                type="primary"
                icon={<HiSearch />}
                htmlType="submit"
                onClick={() => {
                  form.submit();
                }}
              >
                {t('btn.search')}
              </CustomButton>
            </div>,
          ]}
          onClose={onCloseDrawer}
          open={openDrawer}
        >
          <SearchForm column="col-1" initialValues={initialValues} form={form} onFinish={handleSearch}>
            {/* tên tài khoản */}
            <SearchItem label={t('permission.account.username')} name={'username'}>
              <CustomInput className="!rounded-none" />
            </SearchItem>
            {/* Tên nhân viên/Nhà cung cấp */}
            <SearchItem label={'Tên nhân viên/Nhà cung cấp'} name={'fullname'}>
              <CustomInput className="!rounded-none" />
            </SearchItem>
            {/* Tài khoản nhà cung cấp */}
            <SearchItem label={'Tài khoản nhà cung cấp'} name={'isSupplier'}>
              <CustomSelect options={[{ value: true, label: "Có" }, { value: false, label: "Không" }]} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
            {/* trạng thái */}
            <SearchItem label={t('permission.account.status')} name={'actived'}>
              <CustomSelect options={statusList} className="!rounded-none" allowClear placeholder="-- Tất cả --" />
            </SearchItem>
            {/* Nhóm người dùng */}
            <SearchItem label={"Nhóm người dùng"} name={'roleId'}>
              <CustomSelect
                mode="multiple"
                allowClear
                value={form.getFieldValue('roleId')}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                options={accountStore.dataDropdownRoles.data?.map((item) => {
                  return {
                    label: item.value,
                    value: item.key,
                  };
                })}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                      <Checkbox
                        // type="text"
                        onChange={(e) => {
                          if (e.target.checked) {
                            form.setFieldValue(
                              'roleId',
                              accountStore.dataDropdownRoles.data.map((e) => e.key),
                            );
                          } else {
                            form.setFieldValue('roleId', []);
                          }
                        }}
                      >
                        {t('permission.account.select-all')}
                      </Checkbox>
                    </Space>
                  </>
                )}
              />
            </SearchItem>
          </SearchForm>
        </SearchBox>
      </div>
    );
  });
};

export default accountPage;
