import { observer } from "mobx-react"
import PageWrapper from "pages/Components/PageWrapper"
import CustomButton from 'base/ui/components/Button';
import { useEffect, useState } from "react";
import BoxContainer from "base/ui/components/BoxContainer";
import { Form, message } from "antd";
import { POCreatRequest } from "models/PurchaseOrder";
import { nanoid } from "@ant-design/pro-components";
import { SYSTEM_TITLE } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "base/routes/routes";
import { purchaseOrderPresenter, purchaseOrderStore } from "stores/root_store";
import { MdSaveAs } from "react-icons/md";
import { IoMdSave } from "react-icons/io";
import { useCheckPermissionByUrl } from "utils/permissionHelper";
import General from "../CreatePO/General";
import PurchaseOrderTabel from "../CreatePO/PurchaseOrderTabel";
import dayjs from "dayjs";


export function createCopyPurchaseOrder() {
    return observer(() => {
        document.title = 'Thêm mới - ' + SYSTEM_TITLE;
        useCheckPermissionByUrl(ROUTES.PURCHASE_ORDER.LIST_PO.LINK, "CREATE")
        const { purchaseOrderId } = useParams();
        const navigate = useNavigate()
        const [formCreate] = Form.useForm()
        const id = nanoid()
        const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
        const [loading, setLoading] = useState(false)

        const handleCreate = async (values: POCreatRequest, isContinue?: boolean) => {
            const request = values.purchaseOrderItems?.map(p => ({
                ...p,
                documentDate: typeof p.documentDate !== 'string' ? dayjs(p.documentDate).format("YYYY-MM-DD[T]HH:mm:ss") : p.documentDate,
                materialType: values.purchaseOrderItems?.at(0)?.materialType,
                materialGroup: values.purchaseOrderItems?.at(0)?.materialGroup,
            }))
            setLoading(true)
            const response = await purchaseOrderPresenter.createPurchaseOrder({ ...values, purchaseOrderItems: request })
            setLoading(false)
            if (response.isSuccess) {
                message.success(response.message)
                if (isContinue) {
                    formCreate.resetFields()
                    getDetails()
                } else {
                    navigate(ROUTES.PURCHASE_ORDER.LIST_PO.LINK)
                }
            } else {
                message.error(response.message)
            }
        }

        const getDetails = async () => {
            if (purchaseOrderId) {
                await purchaseOrderPresenter.getDetailsPurchaseOrder(purchaseOrderStore, purchaseOrderId)
                setLoading(false)
                if (purchaseOrderStore.detailPO) {
                    formCreate.setFieldsValue(purchaseOrderStore.detailPO)
                    const keys = purchaseOrderStore.detailPO.purchaseOrderItems?.map(item => item.purchaseOrderDetailId || '')
                    setEditableRowKeys(keys || [])
                }
            }
        }

        useEffect(() => {
            getDetails()
        }, []);


        return (
            <PageWrapper breadcrumb={[{
                text: "Kế hoạch giao hàng",
                to: ROUTES.PURCHASE_ORDER.LIST_PO.LINK
            }, {
                text: 'Thêm mới'
            }
            ]}
                extras={
                    <>
                        <CustomButton
                            loading={loading}
                            disabled={loading}
                            onClick={() => {
                                formCreate.validateFields().then((value) => {
                                    handleCreate(value);
                                }).catch((error) => { console.log(error) })
                            }}
                            icon={<IoMdSave className="text-base" />}>
                            Lưu
                        </CustomButton>
                        <CustomButton
                            loading={loading}
                            disabled={loading}
                            type="primary"
                            icon={<MdSaveAs className="text-base" />}
                            onClick={() => {
                                formCreate.validateFields().then((value) => {
                                    handleCreate(value, true);
                                }).catch((error) => { console.log(error) })
                            }}
                        >
                            Lưu và tiếp tục thêm mới
                        </CustomButton>
                    </>
                }
            >
                <BoxContainer fullHeight className="!bg-transparent !p-0">
                    <Form
                        autoComplete="off"
                        initialValues={{ purchaseOrderItems: [{ purchaseOrderDetailId: id }] }}
                        layout="vertical"
                        form={formCreate}
                        className="flex flex-col gap-default"
                    >
                        <General isCopy form={formCreate} />
                        <PurchaseOrderTabel
                            editableKeys={editableKeys}
                            id={id}
                            setEditableRowKeys={setEditableRowKeys}

                        />
                    </Form>

                </BoxContainer>
            </PageWrapper>
        )
    })
}